import { Checklist } from "@/models/HistoricoChecklist";
import ApiService from "./ApiService";
import {Anexo, Delete} from "@/models/HistoricoChecklistModel";

export const getHistoricoChecklist = async (codConcessionaria, postData) => {
    const { data } = await ApiService.post(`historicochecklist/${codConcessionaria}`, postData);
    return data;
};

export const exportXlsx = async (codConcessionaria, postData) => {
    const { data } = await ApiService.get(`historicochecklist/${codConcessionaria}`, postData);
    return data;
};

export const getHistoricoChecklistSetor = async (codConcessionaria) => {
    const { data } = await ApiService.get(`setorhistoricochecklist/${codConcessionaria}`);
    return data;
};

export const voltarParaInicioChecklist = async (codConcessionaria, checklist, codCheckList) => {
    const { data } = await ApiService.post(`checklist/voltarInicio/${codConcessionaria}/${checklist}/${codCheckList}`, {});
    return data;
};

export const voltarOrcamentoComplementarChecklist = async (codConcessionaria, checklist, codCheckList) => {
    const { data } = await ApiService.post(`checklist/voltarOrcamentoComplementar/${codConcessionaria}/${checklist}/${codCheckList}`, {});
    return data;
};

export const excluirChecklist = async (codConcessionaria, checklist, codCheckList, postData) => {
    const { data } = await ApiService.post(`checklist/excluir/${codConcessionaria}/${checklist}/${codCheckList}`, postData);
    return data;
};

export const finalizarChecklist = async (codConcessionaria, checklist, codCheckList) => {
    if(Array.isArray(codCheckList)){
        const postData:any = {
            multiples: true,
            codCheckList
        };
        const { data } = await ApiService.post(`checklist/finalizar/${codConcessionaria}/${checklist}/null`, postData);
        
        return data;
    } else {
        const postData:any = {
            multiples: false
        };
        const { data } = await ApiService.post(`checklist/finalizar/${codConcessionaria}/${checklist}/${codCheckList}`, postData);
        
        return data;
    }
};

export const entrarEmContato = async (postData) => {
    const { data } = await ApiService.post(`checklist/historico/entraremcontato`, postData);
    
    return data;
}

export const listAnexos = async (checklist : number,codChecklist: number): Promise<Anexo> =>{
    const { data } = await ApiService.get(`checklist/anexos/list/${checklist}/${codChecklist}`);

    return data;
}

export const deleteAnexo = async (codAnexo : number): Promise<Delete> =>{
    const { data } = await ApiService.get(`checklist/anexos/delete/${codAnexo}`);

    return data;
}

export const store = async (checklist : number,codChecklist: number,payload): Promise<any> =>{
    //para testar em local Host
    // const { data } = await ApiService.post(`https://api.desenvolvimento.acampa.app/api/checklist/anexos/add/${checklist}/${codChecklist}`, payload);
    const { data } = await ApiService.post(`checklist/anexos/add/${checklist}/${codChecklist}`,payload);

    return data;
}

export const tipoAnexo = async (checklist: String): Promise<any> =>{
    const { data } = await ApiService.get(`/checklist/anexos/tipoAnexo/${checklist}`)

    return data;
}

export const concessionariaAnexo = async () =>{
    const { data } = await ApiService.get(`/checklist/anexos/concessionariaAnexo/`)

    return data;
}

export const searchChassi = async (codConcessionaria: string | number, payload ) : Promise<Checklist[]>=>  {
    const { data } = await ApiService.post(`/buscaChassi/${codConcessionaria}`, payload)

    return data;
}

export default { getHistoricoChecklist, getHistoricoChecklistSetor, finalizarChecklist, voltarOrcamentoComplementarChecklist, voltarParaInicioChecklist, excluirChecklist, entrarEmContato, tipoAnexo, concessionariaAnexo };