
    import {useRouter, useRoute} from "vue-router";
    import { defineComponent, onMounted, ref, watch } from 'vue';
    import { Modal } from "bootstrap";

    export default defineComponent({
        name: "filtro-base",
        props: {
            id: {
                type: String,
                required: true
            },
            title: {
                type: String,
                default: "Filtros"
            },
            subtitle: {
                type: String,
                default: ""
            },
            modalWidth: {
                type: String,
                default: "500px"
            },
            disabled: {
                type: Boolean,
                default: false
            },
            showGoToBack: {
                type: Boolean,
                default: false
            },
            hideFilter: {
                type: Boolean,
                default: false
            },
            activeModal: {
                type: Boolean,
                default: false
            },
            disabledClick: {
                type: Boolean,
                default: false
            },
            closeModal: {
                type: Boolean,
                default: false
            },
        },
        emits: ["search"],
        setup(props, { emit }) {
            const modalFiltro:any = ref();
            const modalRefFiltro:any = ref();
            const modalDialogFiltro:any = ref();
            const hideClose:any = ref(false);
            const router = useRouter();

            watch(() => props.disabledClick, () => {
                if(props.disabledClick){
                    modalDialogFiltro.value.classList.add("abled-events");
                    modalRefFiltro.value.classList.add("disabled-events");
                } else {
                    modalDialogFiltro.value.classList.remove("abled-events");
                    modalRefFiltro.value.classList.remove("disabled-events");
                }
            });

            watch(() => props.activeModal, () => {
                openModal(true);
            });

            watch(() => props.closeModal, () => {
                if(modalFiltro.value){
                    modalFiltro.value.hide();
                }
            });
            
            function goTo(routeName){
                router.push(
                    {
                        name: routeName
                    }
                );
                modalFiltro.value.hide();
            };

            function openModal(staticModal = false){
                // document.querySelectorAll(`#filter-${props.id}-modal`).forEach(e => console.log("role 1", e));
                // document.querySelectorAll(`#filter-${props.id}-modal[role]`).forEach(e => console.log("role 2", e));
                // document.querySelectorAll(`#filter-${props.id}-modal[role="dialog"]`).forEach(e => console.log("role 3", e));

                modalDialogFiltro.value = document.getElementById(`filter-${props.id}-modal-dialog`);
                modalRefFiltro.value = document.getElementById(`filter-${props.id}-modal`);
                if(modalRefFiltro.value){
                    document.getElementById("layout_content")?.appendChild(modalRefFiltro.value);
                }

                if(staticModal){
                    modalFiltro.value = new Modal(modalRefFiltro.value, {keyboard: false, backdrop: 'static'});
                    hideClose.value = true;
                } else {
                    modalFiltro.value = new Modal(modalRefFiltro.value);
                    hideClose.value = false;
                }

                modalFiltro.value.show();
            };

            function search(){
                emit("search");
            };

            return {
                openModal,
                search,
                hideClose,
                goTo
            };
        }
    });
