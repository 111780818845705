import { ItensReprovadosModel, GerenciarModel, dados, OficinaDigitalAvancarManual } from "@/models/OficinaDigitalModel";
import ApiService from "./ApiService";
import { GetChartHistoricoOrcamento, GetConsultorModal, GetFaturamento, GetMecanico, GetMecanicoModal, IGrafico, dateConsultor } from "@/models/OficinaDigitalRelatorioCompletoModel";

/**
 * @description lista os itens reprovados da oficina digital
 */
export const getItensReprovados = async (codConcessionaria: number, filters: string, postData:  any) : Promise<Array<ItensReprovadosModel>> => {
    const { data } = await ApiService.post(`/oficinadigitalitensreprovados/${codConcessionaria}/${filters}`, postData);
    return data;
 };
/**
/**
 * @description lista os itens reprovados da oficina digital
 */
export const getPDFItensReprovados = async (distribuidor: string, codConcessionaria: number, filters: string, postData:  any) : Promise<Array<ItensReprovadosModel>> => {
    const { data } = await ApiService.post(`/pdf/itensReprovadosPdf/${distribuidor}/${codConcessionaria}/${filters}`, postData);
    return data;
 };

/**
 * @description lista as opções de filtro dos itens reprovados da oficina digital
 */
export const getOptionsItensReprovados = async (codConcessionaria: number, filters: string) => {
    const { data } = await ApiService.get(`/oficinadigitalitensreprovadosfiltros/${codConcessionaria}/${filters}`);
    return data;
 };
/**
 * @description lista os clientes da oficina digital gerenciar
 */
 export const getOficinaDigitalGerenciar = async (codConcessionaria: number): Promise<dados> => {
      const{ data } = await ApiService.get(`/oficinadigitalgerenciar/${codConcessionaria}`);
  return data;
 };

 export const getOficinaDigitalPrecificar = async (codCheckList: number, codConcessionaria: number, checklist: number): Promise<Array<GerenciarModel>> => {
      const{ data } = await ApiService.get(`/oficinadigitalprecificar/${codCheckList}/${codConcessionaria}/${checklist}`);
  return data;
 };

export const getFotosChecklistPrecificar = async (postData) => {
    const{ data } = await ApiService.post(`/oficinadigitalprecificar/fotosChecklist`, postData);
    return data;
};

 export const getOficinaDigitalAvancarManual = async (postData, codCheckList: number, codConcessionaria: number, checklist: number) : Promise<OficinaDigitalAvancarManual>  => {
    const { data } = await ApiService.post(`/oficinadigitalavancarmanual/${codCheckList}/${codConcessionaria}/${checklist}`, postData);
    return data;

};

export const deleteItemPrecificar = async (postData, codOrcamento: number) => {
    const {data} = await ApiService.post(`/oficinadigitalprecificar/deletar`, postData);
    return data;
};

export const getOficinaDigitalConsultores = async (codConcessionaria: number) => {
    const { data } = await ApiService.get(`/oficinadigitalconsultores/${codConcessionaria}`);
    return data;

};

export const getOficinaDigitalHistoricoDeOrcamentosData = async (codConcessionaria: number,  filters: string) => {
    const { data } = await ApiService.get(`/oficinadigitaldados/data/${codConcessionaria}/${filters}`);
    return data;
};
export const getOficinaDigitalHistoricoDeOrcamentosConsultor = async (codConcessionaria: number,  consultor: string) => {
    const { data } = await ApiService.get(`/oficinadigitaldados/consultor/${codConcessionaria}/${consultor}`);
    return data;
};

export const getOficinaDigitalHistoricoDeOrcamentosPlaca = async (codConcessionaria: number,  placa: string) => {
    const { data } = await ApiService.get(`/oficinadigitaldados/placa/${codConcessionaria}/${placa}`);
    return data;
};

export const getOficinaDigitalHistoricoOrcamentos = async (codConcessionaria, postData) => {
    const { data } = await ApiService.post(`/oficinadigitaldadosfiltro/${codConcessionaria}`, postData);
    return data;
};

export const getPDFOficinaDigitalHistoricoOrcamentos = async (distribuidor: string, codConcessionaria, postData) => {
    const { data } = await ApiService.post(`/pdf/oficinadigitaldadosfiltro/${distribuidor}/${codConcessionaria}`, postData);
    return data;
};

export const atualizaOrcamento = async (postData) => {  //ataliza manualmente a aprovacao dos serviços pelo historico de orçammentos.
    const { data } = await ApiService.post(`/atualizarorcamento`, postData);
    return data;
};

export const getOficinadigitalfinalizados = async (codConcessionaria: number,  filters: string, codConsultor: string,  placa: string) => {
    const { data } = await ApiService.get(`/oficinadigitalfinalizados/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;
};

export const getOficinadigitalfinalizadosParte1 = async (codConcessionaria: number,  filters: string, codConsultor: string,  placa: string) => {
    const { data } = await ApiService.get(`/oficinadigitalfinalizados/parte1/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;
};
export const getOficinadigitalfinalizadosParte2 = async (codConcessionaria: number,  filters: string, codConsultor: string,  placa: string) => {
    const { data } = await ApiService.get(`/oficinadigitalfinalizados/parte2/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;
};
export const getOficinadigitalfinalizadosParte3 = async (codConcessionaria: number,  filters: string, codConsultor: string,  placa: string) => {
    const { data } = await ApiService.get(`/oficinadigitalfinalizados/parte3/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;
};
export const getOficinadigitalfinalizadosParte4 = async (codConcessionaria: number,  filters: string, codConsultor: string,  placa: string) => {
    const { data } = await ApiService.get(`/oficinadigitalfinalizados/parte4/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;
};

/**
 * @description todos Consultores de acordo com o filtro
 */

export const getConsultores = async (codConcessionaria: number,  filters: string, codConsultor: string,  placa: string) : Promise<dateConsultor> => {
    const { data } = await ApiService.get(`/oficina-digital/relatorio-completo/consultores/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;
};

/**
 * @description todos mecanicos de acordo com o filtro
 */

export const getMecanicos = async (codConcessionaria: number,  filters: string, codConsultor: string,  placa: string) : Promise<GetMecanico[]> =>  {
    const { data } = await ApiService.get(`/oficina-digital/relatorio-completo/mecanicos/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;
};

/**
 * @description rotas apenas para os modais do mecanico e consultor
 */

export const getConsultorModal = async (codConcessionaria: string,  filters: string, codConsultor: string,  placa: string)  : Promise<GetConsultorModal>  =>{
    const { data } = await ApiService.get(`/oficina-digital/modal/consultores/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;''
};

export const getMecanicoModal = async (codConcessionaria: string,  filters: string, codMecanico: string,  placa: string)  : Promise<GetMecanicoModal>  =>{
    const { data } = await ApiService.get(`/oficina-digital/modal/mecanicos/${codConcessionaria}/${filters}/${codMecanico}/${placa}`);
    return data;''
};

/**
 * @description pegar o faturamento de acordo com o filtro
 */

export const getFaturamento = async (codConcessionaria: string,  filters: string, codConsultor: string,  placa: string)  : Promise<GetFaturamento>  =>{
    const { data } = await ApiService.get(`/oficina-digital/faturamentos/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;''
};

/**
 * @description  grafico Histórico
 */

export const graficoHistorico = async (codConcessionaria: string,  filters: string, codConsultor: string,  placa: string)  : Promise<IGrafico[]>  =>{
    const { data } = await ApiService.get(`/oficina-digital/grafico-historico/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;''
};

/**
 * @description  Histórico de orçamentos realizados
 */

export const getGraficoHistoricoRealizado = async (codConcessionaria: string,  filters: string, codConsultor: string,  placa: string)  : Promise<GetChartHistoricoOrcamento>  =>{
    const { data } = await ApiService.get(`/oficina-digital/grafico-historico-orcamento/${codConcessionaria}/${filters}/${codConsultor}/${placa}`);
    return data;''
};

export const getLogEtapa = async (codConcessionaria: number,  checklist: number, codCheckList: number) => {
    const { data } = await ApiService.get(`/getLogEtapa/${codConcessionaria}/${checklist}/${codCheckList}`);
    return data;
};
export const getNegociacaoList = async (codConcessionaria: number,  checklist: number, codCheckList: number) => {
    const { data } = await ApiService.get(`/negociacaolist/${codCheckList}/${codConcessionaria}/${checklist}`);
    return data;
};

export const setFinalizarNegociacao = async (codConcessionaria: number,  checklist: number, codCheckList: number) => {
    const { data } = await ApiService.get(`/finalizarnegociacao/${codCheckList}/${codConcessionaria}/${checklist}`);
    return data;
};

export const setOrcamentoNegociacao = async (postData) => {  //ataliza manualmente a aprovacao dos serviços pelo historico de orçammentos.
    const { data } = await ApiService.post(`/setorcamento`, postData);
    return data;
};

export const getoficinaDigitalFinalizadosPdf = async (distribuidor, codConcessionaria: number, dataTratada: string, consultor: string, placa: string, showDiarioPDF: boolean) => {
   const { data } = await ApiService.get(`/pdf/oficinaDigitalFinalizadosPdf/${distribuidor}/${codConcessionaria}/${dataTratada}/${consultor}/${placa}/${showDiarioPDF}`);
   return data;
};

export const getAbrirlink = async (postdata) => {
    const { data } = await ApiService.post(`/abrirlink`, postdata);
    return data;
 };


export const getoficinaDigitalmodalConsultoresFinalizadosPdf = async (distribuidor, codConcessionaria: number, dataTratada: string, consultor: string, placa: string) => {
    const { data } = await ApiService.get(`/pdf/oficinaDigitalmodalConsultoresFinalizadosPdf/${distribuidor}/${codConcessionaria}/${dataTratada}/${consultor}/${placa}`);
    return data;
};

export const getoficinaDigitalmodalMecanicosFinalizadosPdf = async (distribuidor, codConcessionaria: number, dataTratada: string, mecanico: string, placa: string) => {
    const { data } = await ApiService.get(`/pdf/oficinaDigitalmodalMecanicosFinalizadosPdf/${distribuidor}/${codConcessionaria}/${dataTratada}/${mecanico}/${placa}`);
    return data;
 };

export const uploadFoto = async (postData, checklist: number, codCheckList: number) => {
    const {data} = await ApiService.post(`/checklist/acampa/v1/${checklist}/foto/${codCheckList}`, postData);
    return data;
};

 export const getvoltarEtapa = async (codCheckList: number,  codConcessionaria: string, checklist: string) => {
    const { data } = await ApiService.get(`/voltaretapa/${codCheckList}/${codConcessionaria}/${checklist}`);
    return data;
};
export const getvoltarstatus = async (codCheckList: number,  codConcessionaria: string, checklist: string) => {
    const { data } = await ApiService.get(`/voltarstatus/${codCheckList}/${codConcessionaria}/${checklist}`);
    return data;
};

export const uploadVideo = async (postData, checklist: number, codCheckList: number) => {
    const {data} = await ApiService.post(`/checklist/acampa/v1/${checklist}/video/${codCheckList}`, postData);
    return data;
};

export const buscarPeca = async (postData) => {
    const {data} = await ApiService.postIntegracao(`/oficinadigitalconsultardms`, postData);
    return data;
};

export const avancarEtapa = async (postData) => {
    const { data } = await ApiService.post('/avancaretapa/', postData);
    return data;
};

export default { getPDFItensReprovados, getOptionsItensReprovados, getOficinaDigitalGerenciar , getItensReprovados, getOficinaDigitalPrecificar,
     getOficinaDigitalAvancarManual, getOficinaDigitalConsultores, getOficinaDigitalHistoricoDeOrcamentosData, getPDFOficinaDigitalHistoricoOrcamentos, getOficinaDigitalHistoricoOrcamentos,
     getOficinaDigitalHistoricoDeOrcamentosPlaca, atualizaOrcamento, getOficinadigitalfinalizados, getLogEtapa,getNegociacaoList,
     setOrcamentoNegociacao, setFinalizarNegociacao, getoficinaDigitalFinalizadosPdf, getAbrirlink, getoficinaDigitalmodalConsultoresFinalizadosPdf, getoficinaDigitalmodalMecanicosFinalizadosPdf, 
     getvoltarEtapa, getvoltarstatus, uploadFoto, uploadVideo, buscarPeca, avancarEtapa, getFotosChecklistPrecificar
};